import axios from 'axios'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

export const updateGrantApplication = async (
  conversationId: string,
  data: string
) => {
  const config = {
    method: 'post',
    withCredentials: true,
    ...configHeader,
    data: JSON.stringify({
      id: conversationId,
      data: data,
    }),
  }

  await axios(`${API_URL}/grant-thread`, config)
}
