'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import lottieSearchingAnimation from '../../../../../public/lottieSearching.json'

import { GrantApplication } from '@/types/grants'

interface AgentResponseStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  message: string
  loading?: boolean
  grantApplication?: GrantApplication
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  reset,
  setCurrentStep,
  getAnswerWrapper,
  message,
  loading,
  grantApplication,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()
  const [text, setText] = useState(message)
  const [enhancing, setEnhancing] = useState(false)

  useEffect(() => {
    if (message) {
      setText(message)
    } else if (grantApplication?.steps['1']?.text) {
      setText(grantApplication?.steps['1']?.text)
    }
  }, [message, grantApplication?.steps['1']?.text])

  const finish = async () => {
    setCurrentStep((currentStep) => currentStep + 1)
  }

  const enhance = async () => {
    setEnhancing(true)
    await getAnswerWrapper(
      `Enhance company information with this additional information: ${form.getFieldValue('additionalInfo')}\n\n Respond with all information about the company that you have gathered.`,
      true
    )
    form.setFieldValue('additionalInfo', '')
    setEnhancing(false)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-4 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      {text && <h2 className='text-xl font-bold'>Company Summary</h2>}
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6'
        layout='vertical'
        requiredMark='optional'
      >
        <div className='flex flex-col gap-6'>
          {text ? (
            <div
              id='scroller'
              className={cn(
                'markdown-answer break-words text-left text-sm sm:text-base',
                enhancing ? 'opacity-50' : ''
              )}
            >
              {markdown(text)}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='pointer-events-none m-auto h-full max-w-[500px]'>
              <Lottie options={{ animationData: lottieSearchingAnimation }} />
            </div>
          )}

          {!loading || enhancing ? (
            <>
              {!enhancing && (
                <Form.Item
                  label='Additional information'
                  name='additionalInfo'
                  rules={[{ type: 'string' }]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder='Add additional here like information about the project, objectives of the company, and the types of grants you are interested in if you already have an idea (i.e. SBIR/STTR)'
                  />
                </Form.Item>
              )}

              <div className='m-0 mt-4 flex justify-end gap-2'>
                <Button
                  className='flex items-center'
                  disabled={enhancing}
                  onClick={reset}
                >
                  Restart
                </Button>
                <Button
                  className='flex items-center'
                  icon={<RiSparklingFill className='size-5' />}
                  disabled={enhancing}
                  onClick={enhance}
                  loading={enhancing}
                >
                  {enhancing ? 'Enhancing...' : 'Enhance'}
                </Button>
                <Form.Item className='m-0'>
                  <Button
                    className='flex items-center'
                    htmlType='submit'
                    type='primary'
                    disabled={enhancing}
                  >
                    Next
                  </Button>
                </Form.Item>
              </div>
            </>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={`data:image/png;base64, ${selectedAgent.avatar}`}
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={16}
          height={16}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default AgentResponseStep
