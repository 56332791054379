'use client'

import { Button, Checkbox, Form, Input, Tooltip } from 'antd'
import { SetStateAction, useState } from 'react'

import { GrantApplication } from '@/types/grants'

const options = [
  "I'm applying as a minority (e.g., racial or ethnic minority)",
  "I'm applying as a veteran",
  "I'm applying as a person with disability",
  "I'm applying as female",
  "I'm applying as non-binary or gender non-conforming",
  "I'm applying as LGBTQ+",
  "I'm applying as an immigrant or refugee",
  "I'm applying as an indigenous person",
  "I'm applying as another underrepresented or disadvantaged group",
  'None of the above',
]

interface ProtectedGroupStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  setProtectedGroup: (protectedGroup: string) => void
  grantApplication?: GrantApplication
  storeGrantApplication: (grantApplication: GrantApplication) => Promise<void>
  mode?: 'new' | 'existing'
}

const ProtectedGroupStep: React.FC<ProtectedGroupStepProps> = ({
  reset,
  setCurrentStep,
  setProtectedGroup,
  grantApplication,
  storeGrantApplication,
  mode,
}) => {
  const [form] = Form.useForm()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const finish = async (values: { [x: string]: string }) => {
    let groups = ''
    const statements = values.identity ?? []
    for (const statement of statements) {
      groups += `${statement}\n`
    }
    groups += values.additionalInfo
      ? `\n\n Additional info: ${values.additionalInfo}\n`
      : ''
    setProtectedGroup(groups)
    setCurrentStep((currentStep) => currentStep + 1)
    storeGrantApplication({
      currentStep: 3,
      mode,
      steps: {
        ...grantApplication?.steps,
        '2': {
          groups: values.identity,
          additionalInfo: values.additionalInfo,
        },
      },
    })
  }

  const handleChange = (checkedValues: string[]) => {
    const added = checkedValues.length > selectedOptions.length
    if (added) {
      const addedOption = checkedValues.find(
        (option) => !selectedOptions.includes(option)
      )
      if (addedOption === 'None of the above') {
        setSelectedOptions(['None of the above'])
        form.setFieldsValue({ identity: ['None of the above'] })
      } else {
        const newValues = checkedValues.filter(
          (option) => option !== 'None of the above'
        )
        setSelectedOptions(newValues)
        form.setFieldsValue({ identity: newValues })
      }
    } else {
      const newValues = checkedValues.filter(
        (option) => option !== 'None of the above'
      )
      setSelectedOptions(newValues)
      form.setFieldsValue({ identity: newValues })
    }
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <div className='flex flex-col gap-2'>
        <h2 className='text-xl font-bold'>Protected group information</h2>
        <Tooltip
          title={`To help us match you with the best grants, we need to gather some information about the company's owners/founders. Many grants are designed to support specific groups, and knowing more about the company's owners will allow us to find more eligible opportunities.`}
        >
          <p>
            Please indicate if any of the following apply to the company (select
            all that apply):
          </p>
        </Tooltip>
      </div>

      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-4'
        requiredMark='optional'
        layout='vertical'
        initialValues={{
          identity: grantApplication?.steps['2']?.groups,
          additionalInfo: grantApplication?.steps['2']?.additionalInfo,
        }}
      >
        <div className='items flex flex-col'>
          <Form.Item name='identity' className='w-full'>
            <Checkbox.Group
              className='flex flex-col gap-1'
              options={options}
              value={selectedOptions}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            label='Additional information'
            name='additionalInfo'
            rules={[{ type: 'string' }]}
          >
            <Input.TextArea
              rows={3}
              placeholder='Share any additional details about the company. Examples include previous grants applied for with preference for protected groups.'
            />
          </Form.Item>
        </div>

        <div className=''>
          <h4 className='font-semibold'>Privacy and Confidentiality Note:</h4>
          <p>
            Your responses will be used solely to match you with grants you may
            be eligible for. We take your privacy seriously and will not share
            your information with any third parties without your consent.
          </p>
        </div>

        <div className='m-0 flex justify-end gap-2'>
          <Button className='flex items-center' onClick={reset}>
            Restart
          </Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default ProtectedGroupStep
