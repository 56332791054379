'use client'

import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import { ConversationDetails } from '@/hooks/useConversation'

import CompanyInfoStep from '@/components/Admin/Matcher/Steps/CompanyInfoStep'
import FeedbackStep from '@/components/Admin/Matcher/Steps/FeedbackStep'
import ProtectedGroupStep from '@/components/Admin/Matcher/Steps/ProtectedGroupStep'
import SpecificProjectStep from '@/components/Admin/Matcher/Steps/SpecificProjectStep'

import { getAnswer, getDrafts, mapConversation } from '@/service/Chatbot'
import { updateGrantApplication } from '@/service/grants'

import GrantEditor from './GrantEditor'
import AgentResponseStep from './Steps/AgentResponseStep'
import GrantInfoStep from './Steps/GrantInfoStep'
import SelectGrantStep from './Steps/SelectGrantStep'
import UploadGrantStep from './Steps/UploadGrantStep'
import lottieChatLoadingScreenAnimation from '../../../../public/lottieChatLoadingScreen.json'

import { IQuestion } from '@/types/chatbot'
import { Section } from '@/types/document'
import { GrantApplication } from '@/types/grants'

interface MatcherProps {
  mutateConversations?: () => void
  setMatcherOpen?: (matcherOpen: boolean) => void
  grantApplication?: GrantApplication
  conversationId?: string
  conversation?: ConversationDetails[]
}

const Matcher: React.FC<MatcherProps> = ({
  mutateConversations,
  setMatcherOpen,
  grantApplication,
  conversationId,
  conversation,
}) => {
  const [mode, setMode] = useState<'new' | 'existing' | undefined>()
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [protectedGroup, setProtectedGroup] = useState<string>('')
  const { agents, error: agentsError, selectedAgent } = useAgents()
  const { user } = useAuth()
  const [questions, setQuestions] = useState([] as IQuestion[])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [finishedSteps, setFinishedSteps] = useState(0)
  const [percent, setPercent] = useState(3)
  const [projectDetails, setProjectDetails] = useState<{
    [x: string]: string
  }>()
  const [progressException, setProgressException] = useState(false)
  const [sections, setSections] = useState<Section[]>()
  const [existingGrantDocument, setExistingGrantDocument] = useState<string>()

  // when conversation is loaded, map it to questions
  useEffect(() => {
    if (conversation && agents && !agentsError) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation, agents])

  useEffect(() => {
    setMode(grantApplication?.mode)
    setCurrentStep(grantApplication?.currentStep ?? -1)
  }, [grantApplication])

  useEffect(() => {
    if (currentStep === 4) {
      if (finishedSteps === 5 || grantApplication?.steps['4']?.text) {
        setPercent(100)
        return
      }

      // Calculate the target percent with a slight overshoot to indicate progression beyond the current step
      const targetPercent = finishedSteps * 20 + (finishedSteps + 1) * 3 // Adjust the overshoot value as needed
      const interval = setInterval(() => {
        setPercent((p) => {
          // Calculate the difference between the target (with overshoot) and the current progress
          const diff = targetPercent - p
          // Adjust the progress, moving slower as it gets closer to the target
          return p + diff / 15 // Adjust the divisor to control the "slowness"
        })
      }, 100)
      return () => clearInterval(interval)
    }
  }, [currentStep, finishedSteps, grantApplication?.steps['4']?.text])

  const gatherGrants = async (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    setLoading(true)
    let specificProject = ''
    if (projectDetails) {
      specificProject = `<IMPORTANT>My specific project details: 
        Project budget: ${projectDetails.budget ?? ''}
        Project timeline: ${projectDetails.timeline ?? ''}
        Previous grants: ${projectDetails.previousGrants ?? ''}
        Additional information: ${projectDetails.additionalInformation ?? ''}
      .</IMPORTANT>`
    } else {
      specificProject = 'I have no specific project.'
    }

    const info = additionalInfo
      ? `<AdditionalInfo>${additionalInfo}</AdditionalInfo>`
      : ''

    const question = `${specificProject}
    <IMPORTANT>${protectedGroup}</IMPORTANT>
    
    ${info}
    `

    await getDraftsWrapper(question, tmpQuestions)
  }

  useEffect(() => {
    if (!loading) {
      if (currentStep === 1) {
        storeGrantApplication({
          currentStep: 1,
          mode,
          steps: {
            ...grantApplication?.steps,
            '1': {
              text: questions[questions.length - 1]?.messages[1]?.message,
            },
          },
        })
        mutateConversations && mutateConversations()
      } else if (currentStep === 4) {
        storeGrantApplication({
          currentStep: 4,
          mode,
          steps: {
            ...grantApplication?.steps,
            '4': {
              text: questions[questions.length - 1]?.messages[1]?.message,
            },
          },
        })
      } else if (currentStep === 5) {
        storeGrantApplication({
          currentStep: 5,
          mode,
          steps: {
            ...grantApplication?.steps,
            '5': {
              text: questions[questions.length - 1]?.messages[1]?.message,
            },
          },
        })
      } else if (currentStep === 6) {
        storeGrantApplication({
          currentStep: 6,
          mode,
          steps: {
            ...grantApplication?.steps,
            '6': {
              text: questions[questions.length - 1]?.messages[1]?.message,
            },
          },
        })
        mutateConversations && mutateConversations()
      }
    }
  }, [loading])

  const reset = () => {
    setCurrentStep(-1)
    setFinishedSteps(0)
    setProgressException(false)
    setQuestions([])
    setPercent(0)
    setMode(undefined)
  }

  const retry = async (additionalInfo: string) => {
    setProgressException(false)
    setPercent(0)
    setFinishedSteps(0)
    const tmpQuestions = questions.slice(0, 1)
    setQuestions(tmpQuestions)
    await gatherGrants(additionalInfo, tmpQuestions)
  }

  const storeGrantApplication = async (grantApplication: GrantApplication) => {
    if (conversationId) {
      updateGrantApplication(conversationId, JSON.stringify(grantApplication))
    }
  }

  const getAnswerWrapper = async (question: string, streaming: boolean) => {
    if (!selectedAgent || !agents) return
    await getAnswer(
      true,
      question,
      selectedAgent.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      false,
      user?.email,
      true,
      conversationId,
      streaming
    )
  }

  const getDraftsWrapper = async (
    question: string,
    tmpQuestions?: IQuestion[]
  ) => {
    if (!selectedAgent || !agents) return
    await getDrafts(
      true,
      question,
      selectedAgent.id,
      tmpQuestions ?? questions,
      agents,
      setLoading,
      setQuestions,
      t,
      setProgressException,
      setFinishedSteps,
      user?.email,
      conversationId
    )
  }

  if (mode === 'new') {
    return (
      <div className='flex size-full overflow-y-auto'>
        {currentStep === 0 ? (
          <CompanyInfoStep
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 1 ? (
          <AgentResponseStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            getAnswerWrapper={getAnswerWrapper}
            message={
              questions[questions.length - 1]?.messages[1]?.message ?? ''
            }
            loading={loading}
            grantApplication={grantApplication}
          />
        ) : currentStep === 2 ? (
          <ProtectedGroupStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            setProtectedGroup={setProtectedGroup}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 3 ? (
          <SpecificProjectStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            gatherGrants={gatherGrants}
            setProjectDetails={setProjectDetails}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 4 ? (
          <SelectGrantStep
            reset={reset}
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
            message={
              questions[questions.length - 1]?.messages[1]?.message ?? ''
            }
            percent={percent}
            loading={loading}
            setLoading={setLoading}
            retry={retry}
            progressException={progressException}
            grantApplication={grantApplication}
          />
        ) : currentStep === 5 ? (
          <GrantInfoStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            getAnswerWrapper={getAnswerWrapper}
            message={
              questions[questions.length - 1]?.messages[1]?.message ?? ''
            }
            loading={loading}
            setLoading={setLoading}
            grantApplication={grantApplication}
          />
        ) : currentStep === 6 ? (
          <GrantEditor
            document={
              questions[questions.length - 1]?.messages[1]?.message ?? ''
            }
            questions={questions}
            getAnswerWrapper={getAnswerWrapper}
            sections={sections}
            setSections={setSections}
            setCurrentStep={setCurrentStep}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
            loading={loading}
          />
        ) : currentStep === 7 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            reset={reset}
            setCurrentStep={setCurrentStep}
            setMatcherOpen={setMatcherOpen}
            loading={!questions[questions.length - 1]?.messages[1]?.message}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 8 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  if (mode === 'existing') {
    return (
      <div className='flex size-full overflow-y-auto'>
        {currentStep === 5 ? (
          <UploadGrantStep
            setCurrentStep={setCurrentStep}
            setExistingGrantDocument={setExistingGrantDocument}
          />
        ) : currentStep === 6 ? (
          <GrantEditor
            document={existingGrantDocument}
            sections={sections}
            setSections={setSections}
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
            questions={questions}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 7 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            reset={reset}
            setCurrentStep={setCurrentStep}
            setMatcherOpen={setMatcherOpen}
            loading={!questions[questions.length - 1]?.messages[1]?.message}
            grantApplication={grantApplication}
            storeGrantApplication={storeGrantApplication}
            mode={mode}
          />
        ) : currentStep === 8 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className='flex size-full overflow-y-auto'>
      <div className='m-auto flex flex-col gap-5'>
        <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
          <Lottie
            options={{ animationData: lottieChatLoadingScreenAnimation }}
          />
        </div>
        <Button
          size='large'
          className='pulsate-infinite'
          type='primary'
          onClick={() => {
            setMode('new')
            setCurrentStep(0)
          }}
        >
          I want to start a new grant
        </Button>
        <Button
          size='large'
          className='pulsate-infinite2'
          type='primary'
          onClick={() => {
            setMode('existing')
            setCurrentStep(5)
          }}
        >
          I have an existing grant I want to refine
        </Button>
      </div>
    </div>
  )
}

export default Matcher
