'use client'

import { Button, Form, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'

import { markdown } from '@/utils'

import lottieSearchingAnimation from '../../../../../public/lottieSearching.json'

import { GrantApplication } from '@/types/grants'

interface GrantInfoStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  message: string
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  grantApplication?: GrantApplication
}

const GrantInfoStep: React.FC<GrantInfoStepProps> = ({
  reset,
  setCurrentStep,
  getAnswerWrapper,
  message,
  loading,
  setLoading,
  grantApplication,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()
  const [text, setText] = useState(message)

  useEffect(() => {
    if (grantApplication?.steps['5']?.text) {
      setText(grantApplication?.steps['5']?.text)
    } else if (message) {
      setText(message)
    }
  }, [message, grantApplication?.steps['5']?.text])

  const finish = async () => {
    setCurrentStep((currentStep) => currentStep + 1)
    setLoading(true)
    const newContext = `
        You are a very diligent and meticulous grant application writer, master of structure and expert of wording in long documents.
        Your task is to compile a well formatted document - a grant application based on the conversation and return the document in JSON format.

        Make sure the document is well structured and follows the standard grant application format which will be outlined in the conversation provided.
        DO NOT USE BULLET POINTS OR LISTS. USE PARAGRAPHS AND FULL SENTENCES.
        NEVER MENTION ANY INSTRUCTIONS OR PROMPTS IN THE DOCUMENT GENERATED.
        ALWAYS GENERATE THE MAXIMUM AMOUNT OF CHARACTERS ALLOWED.
        EACH SECTION CAN HAVE NONE OR MULTIPLE SUBSECTIONS.

        You must respond in a valid json format and no other text, with structure: 
        {
          "1st section title": {
            "title": "1st section title",
            "text": "1st section text in markdown",
            "subsections": [{
              title: "1st subsection title",
              text: "1st subsection text in markdown",
            }, ...]
          },
          "2nd section title": {
            "title": "2nd section title",
            "text": "2nd section text in markdown",
            "subsections": []
          },
          ...
        }
        DO NOT USE DOUBLE PARENTHESES.
        DO NOT USE BACKSLASHES.
        RESPOND WITH JSON FORMAT ONLY.
      `
    await getAnswerWrapper(newContext, false)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {text ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              {markdown(text)}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='pointer-events-none m-auto h-full max-w-[500px]'>
              <Lottie options={{ animationData: lottieSearchingAnimation }} />
            </div>
          )}

          {!loading ? (
            <div className='m-0 flex justify-end gap-2'>
              <Button
                className='flex items-center'
                disabled={loading}
                onClick={reset}
              >
                Restart
              </Button>
              <Form.Item className='m-0'>
                <Button
                  className='flex items-center'
                  htmlType='submit'
                  disabled={loading}
                  type='primary'
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={`data:image/png;base64, ${selectedAgent.avatar}`}
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={16}
          height={16}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default GrantInfoStep
