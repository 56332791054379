'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Tooltip } from 'antd'
import { SetStateAction } from 'react'

import { GrantApplication } from '@/types/grants'

const instruction = `Research my company with the search and scrape function and context and list in detail the information you learned about my company. Present me with a summary of the information gathered.`

interface CompanyInfoStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  setCurrentStep: (value: SetStateAction<number>) => void
  grantApplication?: GrantApplication
  storeGrantApplication: (grantApplication: GrantApplication) => Promise<void>
  mode?: 'new' | 'existing'
}

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({
  getAnswerWrapper,
  setCurrentStep,
  grantApplication,
  storeGrantApplication,
  mode,
}) => {
  const [form] = Form.useForm()

  const finish = async (values: { [x: string]: string }) => {
    setCurrentStep((currentStep) => currentStep + 1)
    const newContext = `Company URL: ${values.companyUrl}, Address: ${values.address}, Number of employees: ${values.numberOfEmployees}, Annual revenue: ${values.annualRevenue}, Additional company information: ${values.additionalInfo}.`
    await getAnswerWrapper(`${newContext}\n ${instruction}`, true)
    storeGrantApplication({
      currentStep: 0,
      mode,
      steps: {
        ...grantApplication?.steps,
        '0': values,
      },
    })
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <div className='flex flex-col gap-2'>
        <h2 className='text-xl font-bold'>Company Information</h2>
        <Tooltip
          title={`To match you with the best grants, we need some information about the company. At a minimum, please provide the URL of the company's website. In the fields below, you can add additional information to enhance your search. Your responses are confidential.`}
        >
          <p>
            Enter the company URL to help us find the best grants. Add more
            details below to find additional grants.
          </p>
        </Tooltip>
      </div>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col text-left'
        requiredMark='optional'
        layout='vertical'
        initialValues={grantApplication?.steps['0']}
      >
        <Form.Item
          label='Company URL'
          name='companyUrl'
          rules={[{ type: 'string', required: true }]}
        >
          <Input placeholder='Company URL' />
        </Form.Item>
        <Form.Item label='Address' name='address' rules={[{ type: 'string' }]}>
          <Input placeholder='Address' />
        </Form.Item>
        <Form.Item
          label='Number of employees'
          name='numberOfEmployees'
          rules={[{ type: 'string' }]}
        >
          <Input placeholder='Number of employees' />
        </Form.Item>
        <Form.Item
          label='Annual revenue'
          name='annualRevenue'
          rules={[{ type: 'string' }]}
        >
          <Input placeholder='Annual revenue' />
        </Form.Item>
        <Form.Item
          label='Additional information'
          name='additionalInfo'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea
            rows={4}
            placeholder='Add additional here like information about the project, objectives of the company, and the types grants of you are interested in if you already have an idea (i.e. SBIR/STTR)'
          />
        </Form.Item>

        <div className='m-0 mt-4 flex justify-end gap-2'>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              icon={<RiSparklingFill className='size-5' />}
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CompanyInfoStep
