'use client'

import { Button, Form, Input, Popover, Progress, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'
const { TextArea } = Input

import Link from 'next/link'

import lottieGrantsAnimation from '../../../../../public/lottieGrants.json'

import { GrantApplication } from '@/types/grants'

interface SelectGrantStepProps {
  reset: () => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  setCurrentStep: (value: SetStateAction<number>) => void
  message: string
  percent: number
  progressException: boolean
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  retry: (additionalInfo: string) => void
  grantApplication?: GrantApplication
}

const SelectGrantStep: React.FC<SelectGrantStepProps> = ({
  reset,
  getAnswerWrapper,
  setCurrentStep,
  message,
  percent,
  loading,
  setLoading,
  progressException,
  retry,
  grantApplication,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()
  const [open, setOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [text, setText] = useState<string>()

  useEffect(() => {
    if (!loading) {
      if (grantApplication?.steps['4']?.text) {
        setText(grantApplication?.steps['4']?.text)
      } else if (message) {
        try {
          JSON.parse(message)
          setText(message)
        } catch (e) {
          setText(undefined)
        }
      }
    }
  }, [message, grantApplication?.steps['4']?.text, loading])

  const next = async (values: { [x: string]: string }) => {
    setCurrentStep((currentStep) => currentStep + 1)
    setLoading(true)

    let newContext = values.selectedGrant
      ? `User selected grant: ${values.selectedGrant}`
      : 'Select one grant that most closely aligns with my company.'
    newContext += `\n Provide the real URL for the selected grant. Then list out the criteria for successfully applying to that grant and how my company specifically meets each of these criteria being extremely specific including facts and data both about the grant and the company. List out the typical grant award size for companies based on available data on the web, and include the deadline for applying. Use citations. `
    await getAnswerWrapper(`${newContext}`, true)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      {text && !loading && (
        <div className='flex flex-col gap-2'>
          <h2 className='text-xl font-bold'>Select the most relevant grant</h2>
        </div>
      )}
      <Form
        form={form}
        onFinish={next}
        autoComplete='off'
        className='flex flex-col gap-6'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {text && !loading ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              <div className='flex flex-col gap-6'>
                {JSON.parse(text).grants.map((grant: any, index: number) => (
                  <div key={index} className='flex flex-col gap-2'>
                    <div className='text-lg font-bold'>{grant.name}</div>
                    <ul className='list-disc'>
                      <li>
                        <span className='font-bold'>Criteria:</span>{' '}
                        {grant.criteria}
                      </li>
                      <li>
                        <span className='font-bold'>Alignment:</span>{' '}
                        {grant.alignment}
                      </li>
                      <li>
                        <span className='font-bold'>Amount:</span>{' '}
                        {grant.amount}
                      </li>
                      <li>
                        <span className='font-bold'>Deadline:</span>{' '}
                        {grant.deadline}
                      </li>
                      {grant.url && (
                        <li>
                          <span className='font-bold'>More Information:</span>{' '}
                          <Link
                            href={grant.url}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {grant.url}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                ))}
              </div>
              <div id='anchor' className='opacity-0'>
                a
              </div>
              <div className='pt-6'>
                Select one of the grants listed above which aligns most closely
                with your company or let the AI select the best grant for you.
              </div>
              <Form.Item
                name='selectedGrant'
                rules={[{ type: 'string' }]}
                className='w-full pt-2'
              >
                <Input placeholder='Select one grant from the list' />
              </Form.Item>
            </div>
          ) : (
            <div className='m-auto flex flex-col items-center gap-1'>
              <div className='pointer-events-none h-full max-w-[500px]'>
                <Lottie options={{ animationData: lottieGrantsAnimation }} />
              </div>
              <Progress
                percent={percent}
                showInfo={false}
                status={progressException ? 'exception' : 'active'}
              />
              {progressException ? (
                <>
                  <p>
                    Error occurred while trying to fetch all available grants.
                  </p>
                  <Button
                    className='mt-2'
                    type='default'
                    onClick={() => retry('')}
                  >
                    Retry
                  </Button>
                </>
              ) : (
                <p>Finding the best grants can take a few moments.</p>
              )}
            </div>
          )}

          {!loading && !progressException ? (
            <div className='m-0 flex justify-end gap-2'>
              <Button
                className='flex items-center'
                onClick={reset}
                disabled={open}
              >
                Restart
              </Button>
              <Popover
                trigger='click'
                className='cursor-pointer'
                overlayClassName='w-full max-w-md'
                onOpenChange={setOpen}
                content={
                  <Form
                    autoComplete='off'
                    className='flex w-full flex-col items-end'
                    layout='vertical'
                  >
                    <Form.Item
                      name='additionalInfo'
                      rules={[{ type: 'string' }]}
                      className='w-full'
                    >
                      <TextArea
                        rows={5}
                        placeholder='Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you!'
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        className='w-full'
                      />
                    </Form.Item>
                    <Form.Item className='m-0'>
                      <Button
                        className='flex items-center'
                        htmlType='submit'
                        type='primary'
                        onClick={() => retry(additionalInfo)}
                      >
                        Search again
                      </Button>
                    </Form.Item>
                  </Form>
                }
              >
                <Button className='flex items-center' disabled={open}>
                  Search again
                </Button>
              </Popover>
              <Form.Item className='m-0'>
                <Button
                  className='flex items-center'
                  htmlType='submit'
                  type='primary'
                  disabled={open}
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={`data:image/png;base64, ${selectedAgent.avatar}`}
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={16}
          height={16}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default SelectGrantStep
