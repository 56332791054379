'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input } from 'antd'
import { SetStateAction } from 'react'

import { IQuestion } from '@/types/chatbot'
import { GrantApplication } from '@/types/grants'

interface SpecificProjectStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  setProjectDetails: (projectDetails?: { [x: string]: string }) => void
  gatherGrants: (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => Promise<void>
  grantApplication?: GrantApplication
  storeGrantApplication: (grantApplication: GrantApplication) => Promise<void>
  mode?: 'new' | 'existing'
}

const SpecificProjectStep: React.FC<SpecificProjectStepProps> = ({
  reset,
  setCurrentStep,
  setProjectDetails,
  gatherGrants,
  grantApplication,
  storeGrantApplication,
  mode,
}) => {
  const [form] = Form.useForm()

  const finish = async (values: { [x: string]: string }) => {
    setCurrentStep((currentStep) => currentStep + 1)
    setProjectDetails(values)
    storeGrantApplication({
      currentStep: 3,
      mode,
      steps: {
        ...grantApplication?.steps,
        '3': {
          ...grantApplication?.steps['3'],
          projectDetails: values,
        },
      },
    })
    await gatherGrants()
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <div className='flex flex-col gap-2'>
        <h2 className='text-xl font-bold'>Project Information</h2>
        <p>Do you have a specific project in mind for funding?</p>
      </div>

      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col'
        layout='vertical'
        requiredMark='optional'
        initialValues={grantApplication?.steps['3']?.projectDetails}
      >
        <Form.Item
          label='Project Budget'
          name='budget'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., $1,000,000 for building a spaceship prototype' />
        </Form.Item>
        <Form.Item
          label='Project Timeline'
          name='timeline'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., Start: Jan 2024, End: Dec 2024' />
        </Form.Item>
        <Form.Item
          label='Previous Grants'
          name='previousGrants'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., Received $500,000 from NASA in 2022' />
        </Form.Item>

        <Form.Item
          label='Additional Information'
          name='additionalInformation'
          rules={[{ type: 'string' }]}
          className='w-full'
        >
          <Input.TextArea
            placeholder='e.g., We plan to collaborate with SpaceX for rocket components'
            rows={5}
          />
        </Form.Item>

        <div className='m-0 flex justify-end gap-2'>
          <Button className='flex items-center' onClick={reset}>
            Restart
          </Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              icon={<RiSparklingFill className='size-5' />}
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default SpecificProjectStep
